// src/App.js
import React, { useState } from 'react';
import PasswordEntry from './components/PasswordEntry';
import Letter from './components/Letter';


function App() {
  const [accessGranted, setAccessGranted] = useState(false);

  return (
    <div className="App">
      {!accessGranted ? (
        <PasswordEntry onPasswordSuccess={() => setAccessGranted(true)} />
      ) : (
        <Letter />
      )}
    </div>
  );
}

export default App;
