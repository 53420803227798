import React from 'react';
import Countdown from 'react-countdown';

// Renderer function to customize the display
const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render when the countdown is complete
    return <h1>Time’s up!</h1>;
  } else {
    // Render the countdown
    return (
      <div style={styles.timerContainer}>
        <div style={styles.timeBlock}>
          <span style={styles.timeNumber}>{days}</span>
          <span style={styles.timeLabel}>Days</span>
        </div>
        <div style={styles.timeBlock}>
          <span style={styles.timeNumber}>{hours}</span>
          <span style={styles.timeLabel}>Hours</span>
        </div>
        <div style={styles.timeBlock}>
          <span style={styles.timeNumber}>{minutes}</span>
          <span style={styles.timeLabel}>Minutes</span>
        </div>
        <div style={styles.timeBlock}>
          <span style={styles.timeNumber}>{seconds}</span>
          <span style={styles.timeLabel}>Seconds</span>
        </div>
      </div>
    );
  }
};

// Styling object for a clean UI
const styles = {
  timerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Arial, sans-serif',
    gap: '20px',
  },
  timeBlock: {
    textAlign: 'center',
  },
  timeNumber: {
    fontSize: '40px',
    fontWeight: 'bold',
  },
  timeLabel: {
    fontSize: '14px',
    color: '#555',
  },
};

const CountdownTimer = ({ targetDate }) => {
  return <Countdown date={targetDate} renderer={renderer} />;
};

export default CountdownTimer;
