import React, { useState } from 'react';
import { Button, TextField, Typography } from '@mui/material';
import './PasswordEntry.css'; // Continue using your custom styles if needed

const PasswordEntry = ({ onPasswordSuccess }) => {
    const [password, setPassword] = useState('');
    const correctPassword = 'bukoof'; // Set your password here

    const handleSubmit = (event) => {
        event.preventDefault();
        if (password.toLowerCase() === correctPassword) {
            onPasswordSuccess();
        } else {
            alert('Incorrect Password!');
        }
    };

    return (
        <div className="password-entry">
            <form onSubmit={handleSubmit}>
                <Typography variant="h6" component="h2" style={{ textAlign: 'center', marginBottom: '20px', fontFamily: 'Playwrite MX, Arial, sans-serif', fontWeight: 200}}>
                    Hi Ashi! I made a website for you. If you want to see what's on the other side, <br/>
                    please enter the password. Here's a hint... (It's my middle name 🤔)
                </Typography>
                <TextField
                    label="Password"
                    type="password"
                    variant="outlined"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    fullWidth
                    margin="normal"
                    autoComplete="off"
                />
                <Button type="submit" variant="contained" color="primary">
                    Enter
                </Button>
            </form>
        </div>
    );
};

export default PasswordEntry;
