import React from 'react';
import { Grid, Paper, Typography, Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import { blue } from '@mui/material/colors';


import image1 from '../images/haveli.JPG';
import image2 from '../images/suhoor.JPG';
import image3 from '../images/tiktok.jpg';

// Styled component for image styling
const Img = styled('img')({
  width: '100%', // Ensure the image covers the paper component
  height: 'auto',
  borderRadius: '4px', // Rounded corners for a modern look
});

const ImageGallery = () => {

    const images = [image1, image2, image3
      ];

    return (
        <Container sx={{ 
                    justifyContent: 'center', 
                    alignItems: 'start', 
                    backgroundColor: blue[50], // Light blue background
                    marginBottom: '30px' }}>        
            <Typography variant="h4" component="h2" fontFamily='Playwrite MX, cursive' sx={{ marginTop: 10, textAlign: 'center'}} gutterBottom>
            And let's be honest... We'd make a pretty cute couple 😍
            </Typography>        
            <Grid container spacing={2}  style={{ padding: '40px' }}> {/* Grid container with space between items */}
            {images.map((src, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}> {/* Responsive grid items */}
                    <Paper elevation={3} sx={{ overflow: 'hidden' }}> {/* Paper with shadow */}
                        <Img src={process.env.PUBLIC_URL + src} alt={`Gallery image ${index + 1}` } />
                    </Paper>
                </Grid>
            ))}
            </Grid>
        </Container>

    );
};

export default ImageGallery;
